import React, { useEffect, useState } from 'react'
import { request } from 'graphql-request'
import { Helmet } from 'react-helmet'

import Layout from '@components/layout'

import TopBanner from '@page/koti/top-banner'
import BottomBanner from '@page/koti/bottom-banner'
import FeatureSection from '@page/koti/feature-section'
import AboutSection from '@page/koti/about-section'

const IndexPage = ({ uri }) => {
  const [asunnot, setAsunnot] = useState([])

  useEffect(() => {
    const fetchAsunnot = async () => {
      const { asunnot } = await request(
        'https://api-eu-central-1.graphcms.com/v2/ck38r0dyu41ue01cwht9k4nf0/master',
        `
          {
            asunnot(where: {nosto: true}) {
              pintaAla
              vuokra
              id
              asunnonKuvat {
                handle
                height
                width
              }
              pohjapiirros {
                handle
                height
                width
              }
              nimi
              makuuhuoneet
              kylpyhuoneet
              huoneet
              uusi
            }
          }
        `
      )

      setAsunnot(asunnot)
    }

    fetchAsunnot()
  }, [])

  return (
    <Layout currentLocation={uri}>
      <Helmet>
        <title>Etusivu | Tuulia Kodit</title>
      </Helmet>

      <TopBanner />
      <FeatureSection asunnot={asunnot} />
      <AboutSection />

      <BottomBanner />
    </Layout>
  )
}

export default IndexPage
