import React from 'react'

import Title from '@components/title'
import Button from '@components/button'

import aboutUsImg from '@assets/etusivu.jpg'

const AboutSection = () => {
  return (
    <section className="section section--gray">
      <div className="container flex flex-wrap">
        <div className="w-100-sm w-50 mb4-sm">
          <Title heading="h2" className="mb5">
            Löydä oma Tuulia kotisi!
          </Title>

          <p className="lh-text mb4-sm mb5">
            Olemme kotimainen perheyritys, joka rakennuttaa, myy ja vuokraa
            asuntoja Etelä-Suomen alueella. Tuulia Kodit tunnistaa laadukkaasta
            rakentamisesta, päivittäiseen elämään suunnitelluista viihtyisistä
            asunnoista sekä arkea sujuvoittavasta sijainnista. Tervetuloa
            löytämään uusi Tuulia kotisi!
          </p>

          <Button to="/asunnot" className="mb3 mr4">
            Katso asunnot
          </Button>
          <Button to="/asukkaalle" className="mb3 mr4">
            Asukasinfo
          </Button>
        </div>

        <div className="w-100-sm w-50 pl0-sm pl5">
          <img src={aboutUsImg} alt="about Us" className="shadow-4 br1" />
        </div>
      </div>
    </section>
  )
}

export default AboutSection
