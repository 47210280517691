import React from 'react'

import Title from '@components/title'

import FeatureCards from './feature-cards'

const FeatureSection = ({ asunnot }) => {
  return (
    <section className="section">
      {asunnot.length > 0 ? (
        <div>
          <Title heading="h2" textCenter className="mb5">
            Poimintoja asunnoista
          </Title>
          <FeatureCards asunnot={asunnot} />
          
        </div>
      ) : (
        <div>
          <Title heading="h2" textCenter className="mb4">
            Jokainen asuntomme on juuri nyt koti jollekin vuokralaisistamme
          </Title>
          <p className="mw8 f9-sm f8 center mt0 mb5-sm mb6 tc">
            Asuntojen vapautuessa löydät tästä tiedon vapautuvista kodeista.
          </p>
        </div>
      )}
    </section>
  )
}

export default FeatureSection
