import React from 'react'
import { Link } from 'gatsby'

import { currencyFormatter, areaFormatter } from '@utils'
import Image from '@components/image'
import Title from '@components/title'

import BathroomIcon from './icons/bathroom.inline.svg'
import BedroomIcon from './icons/bedroom.inline.svg'
import RoomsIcon from './icons/rooms.inline.svg'

import './styles.css'

const FeatureCards = ({ asunnot }) => {
  return (
    <div className="mw-1300 center ph3-sm ph4">
      <div className="flex flex-wrap nb4-sm nb6">
        {asunnot.map(asunto => {
          const {
            id,
            asunnonKuvat,
            pohjapiirros,
            uusi,
            nimi,
            vuokra,
            pintaAla,
            makuuhuoneet,
            kylpyhuoneet,
            huoneet
          } = asunto

          return (
            <div
              key={id}
              className="w-100-xs w-50-sm w-50-m w-third-l ph3-sm ph4 mb4-sm mb6"
            >
              <Link
                to={`/asunnot/${id}`}
                className="h-100 flex flex-column no-underline gray db br1 overflow-hidden feature-card-shadow bg-white"
              >
                <div className="aspect-ratio aspect-ratio--4x3 feature-card-img-hover">
                  {asunnonKuvat.length > 0 ? (
                    <Image image={asunnonKuvat[0]} isAbsolute />
                  ) : (
                    <Image image={pohjapiirros} isAbsolute />
                  )}
                  {uusi && (
                    <div className="bg-green white absolute right-0 bottom-0 z-3 ph3 pv2 lh-solid f10 fw5 ttu">
                      Uusi
                    </div>
                  )}
                </div>

                <div className="pv4 ph3 flex-grow-1">
                  <div className="pa2 h-100 flex flex-column">
                    <Title
                      heading="h5"
                      noUnderline
                      fontWeight="normal"
                      className="mb3"
                    >
                      {nimi}
                    </Title>

                    <div className="green f7 fw6 mb4">
                      {currencyFormatter(vuokra)}
                    </div>

                    <div className="flex mt-auto">
                      <span className="fw6">{areaFormatter(pintaAla)}</span>

                      <ul className="flex items-center list ml-auto">
                        <SpecsItem icon={<BedroomIcon />} text={makuuhuoneet} />
                        <SpecsItem
                          icon={<BathroomIcon />}
                          text={kylpyhuoneet}
                        />
                        <SpecsItem icon={<RoomsIcon />} text={huoneet} />
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default FeatureCards

const SpecsItem = ({ icon, text }) => (
  <li className="ml4 flex items-center">
    {icon}
    <span className="ml2 fw6">{text}</span>
  </li>
)
