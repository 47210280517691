import React from 'react'

import Title from '@components/title'
import Button from '@components/button'

import bgTop from './home-top-banner.svg'
import './styles.css'

const TopBanner = () => (
  <section
    className="contain bg-bottom pt5 pb-17"
    style={{ backgroundImage: `url(${bgTop})` }}
  >
    <div className="container tc">
      <Title heading="h1" noUnderline fontWeight="bold" className="ttu fw7 mb3">
        Tuulia Kodit
      </Title>
      <Title
        heading="h5"
        noUnderline
        fontWeight="normal"
        className="ttu mb3-sm mb4 tracked-mega"
      >
        Aito kotisi
      </Title>

      <p className="mw6 f10-sm f9-m f8-l center mt0 mb4 mb5-l">
        Rakennutamme ja vuokraamme laadukkaita ja moderneja koteja Etelä-Suomen
        kasvukeskuksissa.
      </p>

      <div className="tc">
        <Button to="/asunnot" withShadow className="mh3 mb3">
          Katso asunnot
        </Button>
        <Button to="/vuokrahakemus" withShadow className="mh3 mb3">
          Tee vuokrahakemus
        </Button>
      </div>
    </div>
  </section>
)

export default TopBanner
