import React from 'react'

import Title from '@components/title'
import Button from '@components/button'

import bgBottom from './home-bottom-banner.svg'

const BottomBanner = () => (
  <section
    className="contain bg-bottom tc section pb7-sm pb7-m pb8-l"
    style={{ backgroundImage: `url(${bgBottom})` }}
  >
    <div className="container">
      <Title heading="h2" textCenter className="mb4">
        Etsitkö vuokra-asuntoa?
      </Title>
      <p className="mw6 f9-sm f8 center mt0 mb5-sm mb6">
        Tutustu asuntotarjontaamme ja löydä oma Tuulia Kotisi!
      </p>
      <Button to="/asunnot" className="m-auto">
        Katso asunnot
      </Button>
    </div>
  </section>
)

export default BottomBanner
